import { InputFactory } from "./input-factory";

export const CategoryInput = InputFactory("category");
export const CityInput = InputFactory("city");
export const DateInput = InputFactory("date");
export const DescriptionInput = InputFactory("description", true);
export const LinkInput = InputFactory("link");
export const NavigationPathInput = InputFactory("navigationPath");
export const ImageLinkInput = InputFactory("imageLink");
export const NameInput = InputFactory("name");
export const TitleInput = InputFactory("title");

export const AdminNameInput = InputFactory("adminName");

export const AnnouncementsContentInput = InputFactory("announcementContent", true);

export const BranchesAddressInput = InputFactory("branchAddress");
export const BranchesLatitudeInput = InputFactory("branchLatitude");
export const BranchesLongitudeInput = InputFactory("branchLongitude");
export const BranchesFaxInput = InputFactory("branchFax");
export const BranchesHoursInput = InputFactory("branchHours");
export const BranchesWhatsappInput = InputFactory("branchWhatsapp");

export const EmailInput = InputFactory("email");

export const FaqsQuestionInput = InputFactory("faqQuestion");
export const FaqsAnswerInput = InputFactory("faqAnswer", true);

export const FirstNameInput = InputFactory("firstName");

export const KeyInput = InputFactory("key");

export const LastNameInput = InputFactory("lastName");

export const IndexInput = InputFactory("index");

export const OpeningsReferenceIdInput = InputFactory("openingReference");
export const OpeningsRequirementsInput = InputFactory("openingRequirements", true);

export const PagesBodyInput = InputFactory("pageBody", true);
export const PagesRouteInput = InputFactory("pageRoute");
export const PagesSubtitleInput = InputFactory("pageSubtitle");

export const PhoneNumberInput = InputFactory("phoneNumber");

export const SEOTitleInput = InputFactory("seoTitle");
export const SEOMetaNameInput = InputFactory("seoMetaName");
export const SEOMetaContentInput = InputFactory("seoMetaContent");

export const TextInput = InputFactory("text", true);

export const UsernameInput = InputFactory("username");
export const MessageInput = InputFactory("message", true);

export const ClientNameInput = InputFactory("clientName");
export const OrderInput = InputFactory("order");
