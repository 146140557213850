import { API_DB_CONNECTION_NAMES } from "../../communicators/config";
import {
	ActivitiesAdminCommunicator,
	AnnouncementsAdminCommunicator,
	AssetsAdminCommunicator,
	AwardsAdminCommunicator,
	BranchesAdminCommunicator,
	ChecksClientCommunicator,
	DocumentsAdminCommunicator,
	FaqsAdminCommunicator,
	InvoicesClientCommunicator,
	JobOpeningsAdminCommunicator,
	LiderPrivateCommunicator,
	LocalizationsAdminCommunicator,
	NewsAdminCommunicator,
	PagesAdminCommunicator,
	SectionsAdminCommunicator,
	TemplateAdminCommunicator,
	TestimonialsAdminCommunicator,
	UserRoleSubscriptionsAdminCommunicator,
	UsersAdminCommunicator
} from "../../communicators/lider.http.communicator";
import { HttpRequestArgs } from "../../communicators/types";
import {
	baseAdaptor,
	sectionsByLanguageIdAdaptor,
	templatesByIdAdaptor
} from "../adaptors/lider.adaptors";
import { FromManyAdaptor } from "../adaptors/types";

interface IPaginationOptions {
	pageSize: number;
	pageNumber?: number;
}
interface IGeneratorOptions {
	pagination?: IPaginationOptions;
}

const connectionHeaderConfig = { "connection-name": API_DB_CONNECTION_NAMES.draft };

const optionsAdaptors: Record<string, (options: string[]) => any[]> = {
	date: (options: string[]) => [
		...new Set(
			options.map(date => new Date(date).getFullYear().toString()).sort((a, b) => +b - +a)
		)
	],
	dateRange: (options: string[]) => [...new Set(options)].map(date => new Date(date)),
	default: (options: string[]) => options
};

type OptionsAdaptorType = keyof typeof optionsAdaptors;

export const PrivateGeneratorFactory = <DAO, AdaptorResultType>(
	Communicator: { new (args?: Partial<HttpRequestArgs>): LiderPrivateCommunicator<DAO> },
	adaptor: FromManyAdaptor<DAO, AdaptorResultType>,
	options: Record<string, any> = {},
	args?: Partial<HttpRequestArgs>
) => {
	const usePagination = !!options.pagination;
	const pageSize = options.pagination ? options.pagination.pageSize : 1;
	const initialPage = options.pagination ? options.pagination.pageNumber : 0;

	return class AdminGenerator extends Communicator {
		hasMore = false;
		pageSize: number;
		pageCount: number;
		currentPage: number;
		activeRequestCount = 0;
		response: any;
		url: URL = new URL("", "https://_");
		count = -1;

		constructor(
			options: IGeneratorOptions = {},
			private passActiveRequestCount?: (value: number) => void
		) {
			super(args);
			this.pageCount = 0;
			this.pageSize = options.pagination ? options.pagination.pageSize : pageSize;
			this.currentPage =
				options.pagination && options.pagination.pageNumber
					? options.pagination.pageNumber
					: initialPage;
		}

		all = async () => this._callAndAdapt("findAll");

		many = async (query: string, pageNumber?: number) =>
			this._callAndAdapt("findMany", query, pageNumber);

		options = async (property: string, type: OptionsAdaptorType) => {
			this.#incrementActiveRequestCount();
			const options = await this.findOptions(property)
				.then(response => {
					return response.data.options;
				})
				.catch(error => {
					this.#decrementActiveRequestCount();
					throw error;
				});
			this.#decrementActiveRequestCount();
			return optionsAdaptors[type](options);
		};

		_callAndAdapt = async (
			method: "findAll" | "findMany" | "findOptions",
			query?: string,
			pageNumber?: number
		): Promise<AdaptorResultType> => {
			this.#incrementActiveRequestCount();
			this.url = new URL(query ?? "", "https://_");

			if (usePagination) this._createUpdatePaginationParameters(pageNumber);

			await this._createResponse(method);

			this.#decrementActiveRequestCount();
			return this.response;
		};

		_createUpdatePaginationParameters = (pageNumber?: number) => {
			[
				{
					key: "page[size]",
					value: this.pageSize
				},
				{ key: "page[number]", value: pageNumber ?? this.currentPage }
			].forEach(({ key, value }) => this.url.searchParams.set(key, String(value)));

			return pageNumber ? (this.currentPage = pageNumber) : (this.currentPage += 1);
		};

		_createResponse = async (method: "findAll" | "findMany" | "findOptions") => {
			const res = await this[method](
				this.url.pathname.substring(1) + "&" + this.url?.search.substring(1)
			).catch(error => {
				this.#decrementActiveRequestCount();
				throw error;
			});

			if (!res || !res.data) return;
			const { data, links, meta } = res;

			if (meta?.count) this.count = meta?.count;

			const encodedURI = encodeURIComponent("page[number]");
			const pattern = new RegExp(encodedURI + "=(\\d+)");

			const match = links?.last.match(pattern);
			if (match) this.pageCount = Number(match[1]);

			this.hasMore = !!links?.next;
			this.response = adaptor(data);
		};

		#incrementActiveRequestCount = () => {
			this.activeRequestCount++;
			if (this.passActiveRequestCount)
				this.passActiveRequestCount(this.activeRequestCount);
		};
		#decrementActiveRequestCount = () => {
			this.activeRequestCount--;
			if (this.passActiveRequestCount)
				this.passActiveRequestCount(this.activeRequestCount);
		};
	};
};

export const AdminActivitiesGenerator = PrivateGeneratorFactory(
	ActivitiesAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } }
);

export const AdminAnnouncementsGenerator = PrivateGeneratorFactory(
	AnnouncementsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminAssetsGenerator = PrivateGeneratorFactory(
	AssetsAdminCommunicator,
	baseAdaptor,
	{},
	{}
);

export const AdminAwardsGenerator = PrivateGeneratorFactory(
	AwardsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);
export const AdminBranchesGenerator = PrivateGeneratorFactory(
	BranchesAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminDocumentsGenerator = PrivateGeneratorFactory(
	DocumentsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminFaqsGenerator = PrivateGeneratorFactory(
	FaqsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminJobOpeningsGenerator = PrivateGeneratorFactory(
	JobOpeningsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminLocalizationsGenerator = PrivateGeneratorFactory(
	LocalizationsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminNewsGenerator = PrivateGeneratorFactory(
	NewsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminDocumentPagesGenerator = PrivateGeneratorFactory(
	PagesAdminCommunicator,
	baseAdaptor,
	{},
	{ headers: connectionHeaderConfig }
);

export const AdminPagesGenerator = PrivateGeneratorFactory(
	PagesAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminSectionsGenerator = PrivateGeneratorFactory(
	SectionsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminTestimonialsGenerator = PrivateGeneratorFactory(
	TestimonialsAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: connectionHeaderConfig }
);

export const AdminUserRoleSubscriptionsGenerator = PrivateGeneratorFactory(
	UserRoleSubscriptionsAdminCommunicator,
	baseAdaptor
);

export const AdminUsersGenerator = PrivateGeneratorFactory(
	UsersAdminCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } },
	{ headers: { "cache-control": "no-cache" } }
);

export const AdminSectionsByLanguageIdGenerator = PrivateGeneratorFactory(
	SectionsAdminCommunicator,
	sectionsByLanguageIdAdaptor,
	{},
	{ headers: connectionHeaderConfig }
);

export const AdminTemplatesByIdGenerator = PrivateGeneratorFactory(
	TemplateAdminCommunicator,
	templatesByIdAdaptor,
	{}
);

export const ClientChecksGenerator = PrivateGeneratorFactory(
	ChecksClientCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } }
);

export const ClientInvoicesGenerator = PrivateGeneratorFactory(
	InvoicesClientCommunicator,
	baseAdaptor,
	{ pagination: { pageSize: 10, pageNumber: 0 } }
);

export class PrivateEntityByPrimaryKeyGeneratorDictionary {
	static adminActivities = AdminActivitiesGenerator;
	static announcements = AdminAnnouncementsGenerator;
	static assets = AdminAssetsGenerator;
	static awards = AdminAwardsGenerator;
	static branches = AdminBranchesGenerator;
	static documents = AdminDocumentsGenerator;
	static faqs = AdminFaqsGenerator;
	static jobOpenings = AdminJobOpeningsGenerator;
	static localizations = AdminLocalizationsGenerator;
	static news = AdminNewsGenerator;
	static pages = AdminPagesGenerator;
	static sections = AdminSectionsGenerator;
	static testimonials = AdminTestimonialsGenerator;
	static userRoleSubscriptions = AdminUserRoleSubscriptionsGenerator;
	static users = AdminUsersGenerator;
	static checks = ClientChecksGenerator;
	static homepageChecks = ClientChecksGenerator;
	static invoices = ClientInvoicesGenerator;
}
