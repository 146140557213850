import React from "react";
import { Box, Card, CardMedia, CardContent, IconButton, useTheme } from "@mui/material";
import { MoreHoriz as EllipsisIcon } from "@mui/icons-material";
import { GenericBody } from "../../elements";
import { AssetDAO } from "../../../util/generators/types";
import { truncateString } from "../../../util/converters/string.converter";
import { AssetDropdown } from "../dropdowns";
import { ColorDictionary } from "../../../util/dictionaries/color.dictionary";

interface Props {
	asset: AssetDAO;
	setAssetToDelete: (asset: AssetDAO) => void;
	onDownload: (asset: AssetDAO) => void;
}

export const AssetItem: React.FC<Props> = ({ asset, setAssetToDelete, onDownload }) => {
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
		const currentTarget = event?.currentTarget || null;
		const nextAnchorElement = anchorEl ? null : currentTarget;
		setAnchorEl(nextAnchorElement);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		setAssetToDelete(asset);
		handleClose();
	};

	const handleDownload = () => {
		onDownload(asset);
		handleClose();
	};

	return (
		<Card
			sx={{
				width: "100%",
				position: "relative",
				backgroundColor: anchorEl ? ColorDictionary["#a69fbe"] : theme.palette.grey[300],
				"&:hover": {
					backgroundColor: ColorDictionary["#a69fbe"]
				}
			}}
		>
			<CardContent
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					pt: "0.5rem",
					pb: "0.5rem",
					pl: "0.75rem"
				}}
			>
				<GenericBody
					variant="body4-primary-semi-bold"
					text={truncateString(asset.filename, 30)}
				/>
				<IconButton
					onClick={handleClick}
					sx={{
						padding: "0.1rem",
						"&:hover": {
							backgroundColor: "transparent"
						}
					}}
				>
					<EllipsisIcon />
				</IconButton>
				<AssetDropdown
					handleClose={handleClose}
					handleDelete={handleDelete}
					handleDownload={handleDownload}
					anchorEl={anchorEl}
				/>
			</CardContent>
			<Box
				sx={{
					height: "9.625rem",
					width: "97%",
					backgroundColor: theme.palette.common.white,
					borderRadius: "0.75rem",
					overflow: "hidden",
					margin: "auto",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<CardMedia
					component="img"
					sx={{
						height: "98%",
						width: "100%",
						maxWidth: "6.25rem",
						maxHeight: "6.25rem",
						objectFit: "fill",
						borderRadius: "0.5rem"
					}}
					image={asset.link}
					alt={asset.filename}
				/>
			</Box>
		</Card>
	);
};
