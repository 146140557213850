import React from "react";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { Box, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { LocalizedGenericBody } from "../../elements";
import { MakePayment } from "../images";

export const MakePaymentButton = () => {
	const theme = useTheme();
	const { isMobile } = useResponsive();
	const [hover, setHover] = React.useState(false);

	const responsiveStyles = isMobile
		? {
				borderWidth: "1px 0 1px 1px",
				borderStyle: "solid",
				borderColor: theme.palette.primary.main,
				boxShadow: "var(--theme-shadow-dropdown)",
				borderRadius: "0.500rem 0 0 0.500rem"
		  }
		: {
				border: `1px solid ${theme.palette.primary.main}`,
				borderRadius: "0.500rem"
		  };

	return (
		<NavLink
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			style={{
				textDecoration: "none",

				display: "flex",
				alignItems: "center",
				gap: "0.375rem",

				padding: "0.625rem 0 0.625rem 1.00rem",
				isolation: "isolate",

				minWidth: "8.88rem",
				height: "2.25rem",

				background: hover
					? `${theme.palette.primary.dark}1a`
					: theme.palette.common.white,

				overflow: "hidden",

				...responsiveStyles
			}}
			to="//pos.param.com.tr/Tahsilat/Default.aspx?k=b7090539-562d-4584-92d0-2e910cc89898"
			target="_blank"
		>
			<LocalizedGenericBody
				variant="body3-primary-medium"
				localizationKey="make_payment_button_text"
			/>
			<Box
				sx={{
					marginLeft: "auto",
					transform: "rotate(4.54deg) translateX(4.41%)"
				}}
			>
				<MakePayment />
			</Box>
		</NavLink>
	);
};
