import { BaseHTTPCommunicator } from "./base.http.communicator";
import {
	AdminActivitiesDAO,
	AnnouncementsDAO,
	ApplicationDAO,
	AssetDAO,
	AwardDAO,
	BranchDAO,
	CheckDAO,
	DocumentDAO,
	AnyDAO,
	FaqsDAO,
	JobOpeningsDAO,
	LanguageDAO,
	LocalizationDAO,
	NewsDAO,
	PageDAO,
	SectionDAO,
	StockPricesEventDAO,
	TemplateDAO,
	UserRoleSubscriptionDAO,
	UserDAO,
	ClientInformationDAO,
	InvoiceDAO,
	AggregationDAO,
	SyncStatusDAO,
	ReleaseStatusDAO,
	TestimonialDAO
} from "../util/generators/types";
import { CommunicatorResponse, HttpRequestArgs } from "./types";
import { API_URL_CONFIG } from "./config";
import { adminHandler } from "../util/error-handlers/admin.handler";
import { userHandler } from "../util/error-handlers/user.handler";
import { AxiosError } from "axios";
import { LanguageEnum, UserRoleEnum } from "../util/dictionaries/types";

type ArrayResponse<T> = (args?: any) => Promise<CommunicatorResponse<T[]>>;
type SingleResponse<T> = (...args: any[]) => Promise<CommunicatorResponse<T>>;

export abstract class LiderBaseCommunicator<T> extends BaseHTTPCommunicator {
	apiUrl = API_URL_CONFIG.apiUrl;
	accessLevel = "";
	userRole = "";
	endpoint = "";

	constructor(args?: Partial<HttpRequestArgs>) {
		super({
			baseURL: process.env.REACT_APP_LIDER_API_ENDPOINT as string,
			timeout: 15000,
			...args,
			headers: {
				locale:
					localStorage.getItem("language") ||
					(process.env.REACT_APP_DEFAULT_LANGUAGE as string),
				...args?.headers
			}
		});
	}

	createOne: SingleResponse<T> = (args: any) => this.post(this._getFullUrl(), args);
	findAll: ArrayResponse<T> = () => this.getAndCache(this._getFullUrl());
	findOne: SingleResponse<T> = (id: string) => this.get(`${this._getFullUrl()}/${id}`);
	findMany: ArrayResponse<T> = (query: string) =>
		this.getAndCache(`${this._getFullUrl()}?${query}`);
	findOptions: SingleResponse<AnyDAO> = (property: string) =>
		this.getAndCache(`${this._getFullUrl()}/options/${property}`);
	getTotalsBy: SingleResponse<AggregationDAO> = (
		aggregatedField: string,
		groupBy: string,
		query = ""
	) =>
		this.get(
			`${this._getFullUrl()}/aggregate/sum/${aggregatedField}/groupBy/${groupBy}?${query}`
		);
	updateOne: SingleResponse<T> = async (id: string, candidate: Record<string, any>) =>
		this.put(`${this._getFullUrl()}/${id}`, candidate);

	_getFullUrl = () => this.apiUrl + this.accessLevel + this.userRole + this.endpoint;

	errorHandler = (error: AxiosError) => {
		if (this.abortController.signal.aborted) return;
		adminHandler(error);
	};
}

export class LiderPublicAssetsCommunicator extends BaseHTTPCommunicator {
	constructor() {
		super({
			baseURL: process.env.REACT_APP_LIDER_API_ENDPOINT as string,
			timeout: 15000
		});
	}

	getDocument = (fileName: string) =>
		this.get(`${process.env.REACT_APP_LIDER_API_DOCUMENTS_DIRECTORY}/${fileName}`, {
			responseType: "blob"
		});
}

export abstract class LiderPublicCommunicator<T> extends LiderBaseCommunicator<T> {
	PUBLIC = true;
	accessLevel = API_URL_CONFIG.accessLevels.public;

	constructor(args?: Partial<HttpRequestArgs>) {
		super(args);
	}
}

export class AwardsPublicCommunicator extends LiderPublicCommunicator<AwardDAO> {
	endpoint = API_URL_CONFIG.endpoints.awards;
}
export class AnnouncementsPublicCommunicator extends LiderPublicCommunicator<AnnouncementsDAO> {
	endpoint = API_URL_CONFIG.endpoints.announcements;
}
export class ApplicationsPublicCommunicator extends LiderPublicCommunicator<AnyDAO> {
	endpoint = API_URL_CONFIG.endpoints.applications;

	constructor() {
		super({
			headers: { "Content-Type": "multipart/form-data" }
		});
	}

	createOne = (args: any) => this.post(this._getFullUrl(), args);
}
export class BranchesPublicCommunicator extends LiderPublicCommunicator<BranchDAO> {
	endpoint = API_URL_CONFIG.endpoints.branches;
}

interface ContactArgs {
	name: string;
	email: string;
	subject: string;
	message: string;
}
export class ContactPublicCommunicator extends LiderPublicCommunicator<AnyDAO> {
	endpoint = API_URL_CONFIG.endpoints.contact;

	sendMail = (args: ContactArgs) => this.post(this._getFullUrl(), args);
}
export class DocumentsPublicCommunicator extends LiderPublicCommunicator<DocumentDAO> {
	endpoint = API_URL_CONFIG.endpoints.documents;
}
export class FaqsPublicCommunicator extends LiderPublicCommunicator<FaqsDAO> {
	endpoint = API_URL_CONFIG.endpoints.faqs;
}
export class JobOpeningsPublicCommunicator extends LiderPublicCommunicator<JobOpeningsDAO> {
	endpoint = API_URL_CONFIG.endpoints.jobOpenings;
}
export class LanguagePublicCommunicator extends LiderPublicCommunicator<LanguageDAO> {
	endpoint = API_URL_CONFIG.endpoints.languages;

	findAll: ArrayResponse<LanguageDAO> = () =>
		this.getAndCache(this._getFullUrl()).then(response => {
			let data = [];
			if (response && response.data) data = response.data.options;
			return { data };
		});
}
export class LocalizationsPublicCommunicator extends LiderPublicCommunicator<LocalizationDAO> {
	endpoint = API_URL_CONFIG.endpoints.localizations;
}
export class NewsPublicCommunicator extends LiderPublicCommunicator<NewsDAO> {
	endpoint = API_URL_CONFIG.endpoints.news;
}
export class PagePublicCommunicator extends LiderPublicCommunicator<PageDAO> {
	endpoint = API_URL_CONFIG.endpoints.pages;
}
export class SectionPublicCommunicator extends LiderPublicCommunicator<SectionDAO> {
	endpoint = API_URL_CONFIG.endpoints.sections;
}
export class StockPricesPublicCommunicator extends LiderPublicCommunicator<StockPricesEventDAO> {
	endpoint = API_URL_CONFIG.endpoints.stockPrices;

	getStockPrices = () => this.get(this._getFullUrl()).then(data => data);
}

export class LiderAdminAuthCommunicator extends LiderPublicCommunicator<AnyDAO> {
	endpoint = API_URL_CONFIG.endpoints.login;

	login = (args: any) => this.post(`${this._getFullUrl()}/admin`, args);
}

export class LiderClientAuthCommunicator extends LiderPublicCommunicator<AnyDAO> {
	endpoint = API_URL_CONFIG.endpoints.login;

	startLogin = (args: any) => this.post(`${this._getFullUrl()}/client`, args);
	retryLogin = (args: any) => this.post(`${this._getFullUrl()}/client/retry`, args);
	completeLogin = (loginId: string, args: any) =>
		this.post(`${this._getFullUrl()}/client/${loginId}`, args);
}

type CreateOrUpdate = { id?: string; args: any };

export abstract class LiderPrivateCommunicator<T> extends LiderBaseCommunicator<T> {
	PRIVATE = true;
	accessLevel = API_URL_CONFIG.accessLevels.private;

	constructor(args?: Partial<HttpRequestArgs>) {
		super({
			...args,
			headers: {
				authorization: `Bearer ${localStorage.getItem("token")}`,
				...args?.headers
			}
		});
	}

	createOrUpdate: SingleResponse<T> = ({ id, args }: CreateOrUpdate) =>
		!!id ? this.updateOne(id, args) : this.createOne(args);

	delete: SingleResponse<T> = (id: string) =>
		this.axios.delete(`${this._getFullUrl()}/${id}`);

	sendReport = (params: { ids: string[]; emails: string[]; language: LanguageEnum }) =>
		this.post(`${this._getFullUrl()}/report`, params);
}

export class UsersPrivateCommunicator extends LiderPrivateCommunicator<UserDAO> {
	getUser = (userRole: UserRoleEnum) => {
		if (!localStorage.getItem("token")) return Promise.resolve({ data: null });
		this.userRole = "/" + userRole;
		return this.findOne("");
	};

	errorHandler = userHandler;
}

export abstract class LiderAdminCommunicator<T> extends LiderPrivateCommunicator<T> {
	userRole = API_URL_CONFIG.userRoles.admin;
}

export class ActivitiesAdminCommunicator extends LiderAdminCommunicator<AdminActivitiesDAO> {
	endpoint = API_URL_CONFIG.endpoints.adminActivities;
	publishStagingData = () => {
		this.endpoint = "/releases";
		return this.post(`${this._getFullUrl()}`);
	};
	getPublishStatus: SingleResponse<ReleaseStatusDAO> = () => {
		this.endpoint = "/releases";
		return this.get(`${this._getFullUrl()}/status`);
	};
	getSyncStatus: SingleResponse<SyncStatusDAO> = () => {
		this.endpoint = "/clients";
		return this.get(`${this._getFullUrl()}/sync`);
	};
	syncClients = () => {
		this.endpoint = "/clients";
		return this.post(`${this._getFullUrl()}/sync`);
	};
}

export class AnnouncementsAdminCommunicator extends LiderAdminCommunicator<AnnouncementsDAO> {
	endpoint = API_URL_CONFIG.endpoints.announcements;
}
export class ApplicationsAdminCommunicator extends LiderAdminCommunicator<ApplicationDAO> {
	endpoint = API_URL_CONFIG.endpoints.applications;
}
export class AssetsAdminCommunicator extends LiderAdminCommunicator<AssetDAO> {
	endpoint = API_URL_CONFIG.endpoints.assets;
	download = (filename: string) =>
		this.get(`${this._getFullUrl()}/download/${filename}`, {
			responseType: "blob"
		});
}
export class AwardsAdminCommunicator extends LiderAdminCommunicator<AwardDAO> {
	endpoint = API_URL_CONFIG.endpoints.awards;
}
export class BranchesAdminCommunicator extends LiderAdminCommunicator<BranchDAO> {
	endpoint = API_URL_CONFIG.endpoints.branches;
}

export class DocumentsAdminCommunicator extends LiderAdminCommunicator<DocumentDAO> {
	endpoint = API_URL_CONFIG.endpoints.documents;
}
export class FaqsAdminCommunicator extends LiderAdminCommunicator<FaqsDAO> {
	endpoint = API_URL_CONFIG.endpoints.faqs;
}

export class JobOpeningsAdminCommunicator extends LiderAdminCommunicator<JobOpeningsDAO> {
	endpoint = API_URL_CONFIG.endpoints.jobOpenings;
}
export class LocalizationsAdminCommunicator extends LiderAdminCommunicator<LocalizationDAO> {
	endpoint = API_URL_CONFIG.endpoints.localizations;
}
export class NewsAdminCommunicator extends LiderAdminCommunicator<NewsDAO> {
	endpoint = API_URL_CONFIG.endpoints.news;
}

export class PagesAdminCommunicator extends LiderAdminCommunicator<PageDAO> {
	endpoint = API_URL_CONFIG.endpoints.pages;
}

export class SectionsAdminCommunicator extends LiderAdminCommunicator<SectionDAO> {
	endpoint = API_URL_CONFIG.endpoints.sections;
}

export class TemplateAdminCommunicator extends LiderAdminCommunicator<TemplateDAO> {
	endpoint = API_URL_CONFIG.endpoints.templates;
}

export class TestimonialsAdminCommunicator extends LiderAdminCommunicator<TestimonialDAO> {
	endpoint = API_URL_CONFIG.endpoints.testimonials;
}

export class UsersAdminCommunicator extends LiderAdminCommunicator<UserDAO> {
	endpoint = API_URL_CONFIG.endpoints.users;

	activation = (action: "activate" | "deactivate", id: string) =>
		this.put(`${this._getFullUrl()}/activation/${action}/${id}`);

	resetPassword = (query: Record<string, any>) =>
		this.post(`${this._getFullUrl()}/reset-password`, query);
}

export class UserRoleSubscriptionsAdminCommunicator extends LiderAdminCommunicator<UserRoleSubscriptionDAO> {
	endpoint = API_URL_CONFIG.endpoints.userRoleSubscriptions;
}

export abstract class LiderClientCommunicator<T> extends LiderPrivateCommunicator<T> {
	userRole = API_URL_CONFIG.userRoles.client;

	findAll: ArrayResponse<T> = () => this.get(this._getFullUrl());
	findMany: ArrayResponse<T> = (query: string) =>
		this.get(`${this._getFullUrl()}?${query}`);
}

export class ChecksClientCommunicator extends LiderClientCommunicator<CheckDAO> {
	endpoint = API_URL_CONFIG.endpoints.checks;
}

export class ClientInformationClientCommunicator extends LiderClientCommunicator<ClientInformationDAO> {
	endpoint = API_URL_CONFIG.endpoints.clientInformation;
}

export class InvoicesClientCommunicator extends LiderClientCommunicator<InvoiceDAO> {
	endpoint = API_URL_CONFIG.endpoints.invoices;
}
