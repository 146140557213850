import { Stack } from "@mui/material";
import { FormProps } from "../../../util/types/admin-form.types";
import {
	ClientNameInput,
	DateInput,
	LanguageSelect,
	OrderInput,
	TextInput
} from "../inputs";
import { AdminFormCreateUpdateButton } from "../buttons";

export const AdminPanelTestimonialsForm: React.FC<FormProps> = ({ handleSubmit }) => (
	<Stack spacing="0.75rem">
		<ClientNameInput />
		<TextInput />
		<Stack spacing="0.75rem" direction={{ xs: "column", md: "row" }}>
			<LanguageSelect />
			<OrderInput />
		</Stack>
		<DateInput />
		<AdminFormCreateUpdateButton handleClick={handleSubmit} />
	</Stack>
);
