import { Box } from "@mui/material";
import { DateRangeFilter } from "../components";
import { FilterOption } from "../types";
import { DateTime } from "luxon";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import React from "react";
import * as locales from "date-fns/locale";
import * as Sentry from "@sentry/react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { PublicLanguageGenerator } from "../../util/generators/public.generator";
import { registerLocale } from "react-datepicker";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { FilterInputStyles } from "../components/types";

interface Props {
	handleChange: (event: any) => void;
	handleSelectOpen: () => void;
	handleSelectClose: () => void;
	filterOptionsForColumn: FilterOption[];
	localizedText: string;
	clearEvent: React.MouseEvent | null;
	selectedFilterValues: string[];
	styles: FilterInputStyles;
}

export const DateRangeFilterContainer: React.FC<Props> = ({
	handleChange,
	handleSelectOpen,
	handleSelectClose,
	filterOptionsForColumn,
	localizedText,
	selectedFilterValues,
	styles,
	clearEvent
}) => {
	const boxRef = React.useRef<HTMLElement | null>(null);
	const { isMobile } = useResponsive();

	const startDateValue = selectedFilterValues[0]
		? new Date(decodeURIComponent(selectedFilterValues[0]))
		: undefined;
	const endDateValue = selectedFilterValues[1]
		? new Date(decodeURIComponent(selectedFilterValues[1]))
		: undefined;

	const [calendarIsOpen, setCalendarIsOpen] = React.useState(false);
	const [startDate, setStartDate] = React.useState<Date>();
	const [endDate, setEndDate] = React.useState<Date>();

	const { language, languages, setLanguages } = useLanguageContext();
	const localeDictionary = { tr: "tr", en: "en-US" };

	const languageGenerator = new PublicLanguageGenerator();

	const getLocales = React.useCallback(() => {
		if (!languages || languages.length === 0) return;

		const importLocale = (language: keyof typeof localeDictionary) => {
			try {
				const languageCode = localeDictionary[language] || language;
				const locale = locales[languageCode as keyof typeof locales];
				registerLocale(languageCode, locale);
			} catch (error) {
				Sentry.captureMessage(`Failed to register locale for ${language}: ${error}`);
			}
		};

		languages.map(language => importLocale(language as keyof typeof localeDictionary));
	}, [languages]);

	useGeneratorEffect(
		{
			effect: languageGenerator.all,
			onSuccess: setLanguages,
			callback: languageGenerator.abort
		},
		[]
	);

	React.useEffect(() => {
		getLocales();
	}, [getLocales]);

	React.useEffect(() => {
		if (!startDate || !endDate) {
			handleChange([]);
			return;
		}
		closeCalendar();
		handleChange([startDate.toISOString(), endDate.toISOString()]);
	}, [startDate, endDate]);

	const onChange = (dates: [start: any, end: any]) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	React.useEffect(() => {
		if (!clearEvent) return;
		setStartDate(undefined);
		setEndDate(undefined);
	}, [clearEvent]);

	const displayValues = filterOptionsForColumn.map(option =>
		new Date(option.displayValue).getTime()
	);

	const [earliestDate, latestDate] = [Math.min, Math.max].map(
		(func: (...args: number[]) => number) => func(...displayValues)
	);

	const minDate = DateTime.fromJSDate(new Date(earliestDate)).startOf("day").toJSDate();
	const maxDate = DateTime.fromJSDate(new Date(latestDate)).endOf("day").toJSDate();

	const usedLocale = localeDictionary[language as keyof typeof localeDictionary];

	const monthsShown = isMobile ? 1 : 2;

	const openCalendar = () => {
		setCalendarIsOpen(true);
		handleSelectOpen();
	};
	const closeCalendar = () => {
		setCalendarIsOpen(false);
		handleSelectClose();
	};

	return (
		<Box ref={boxRef}>
			<DateRangeFilter
				localizedText={localizedText}
				styles={styles}
				anchorEl={boxRef.current}
				openCalendar={openCalendar}
				closeCalendar={closeCalendar}
				calendarIsOpen={calendarIsOpen}
				monthsShown={monthsShown}
				onChange={onChange}
				minDate={minDate}
				maxDate={maxDate}
				startDate={startDateValue ?? startDate}
				endDate={endDateValue ?? endDate}
				usedLocale={usedLocale}
			/>
		</Box>
	);
};
