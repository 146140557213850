import { TableRow, styled } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { transientOptions } from "../options";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

export const CompanyInformationRow = styled(TableRow)(({ theme }) => ({
	background: theme.palette.common.white,

	borderBottom: `1px solid ${theme.palette.grey[300]}`,

	":last-of-type": { borderBottom: "none" },

	"&:nth-of-type(odd)": {
		background: theme.palette.grey[50]
	}
}));

export const DashboardTableBodyRow = styled(
	TableRow,
	transientOptions
)<{
	$isDeactivated?: boolean;
	$isselected?: boolean;
}>(({ theme, $isDeactivated, $isselected }) => ({
	height: "3.5rem",
	maxHeight: "3.5rem",
	background: $isDeactivated
		? ColorDictionary["#f3f4f6"]
		: $isselected
		? ColorDictionary["#ece8f2"]
		: theme.palette.common.white,
	borderBottom: `1px solid ${theme.palette.grey[300]}`,
	":last-child": { borderBottom: "none" }
}));

export const DashboardTableHeaderRow = styled(TableRow)<{
	variant: "secondary" | "grey";
}>(({ theme, variant }) => {
	const color =
		variant === "secondary" ? theme.palette.secondary.main : theme.palette.grey[300];

	return {
		height: "2.75rem",
		maxHeight: "2.75rem",
		background: color,
		borderBottom: `1px solid ${color}`,
		boxShadow: "var(--theme-shadow-table)"
	};
});

export const SimplifiedTableRow = styled(
	TableRow,
	transientOptions
)<{
	variant: "header" | "body";
}>(({ theme, variant }) => {
	const extendedProps = {
		header: {
			height: "2.75rem",
			background: theme.palette.grey[300],
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
			boxShadow: "var(--theme-shadow-table)"
		},
		body: {
			height: "3.5rem",
			background: theme.palette.common.white
		}
	} as Record<string, CSSProperties>;

	return extendedProps[variant];
});
