import { TableEnum } from "../../types/dashboard-table.types";
import { TableConfig } from "./TableConfig";
import { dashboardTableColumns } from "./dashboard-table-columns";

export class DashboardTableConfig {
	static [TableEnum.adminActivities] = new TableConfig(
		dashboardTableColumns[TableEnum.adminActivities],
		null,
		true
	);

	static [TableEnum.awards] = new TableConfig(
		dashboardTableColumns[TableEnum.awards],
		"AdminAwardsDialog"
	);

	static [TableEnum.announcements] = new TableConfig(
		dashboardTableColumns[TableEnum.announcements],
		"AdminAnnouncementsDialog"
	);

	static [TableEnum.branches] = new TableConfig(
		dashboardTableColumns[TableEnum.branches],
		"AdminBranchesDialog"
	);

	static [TableEnum.checks] = new TableConfig(dashboardTableColumns[TableEnum.checks]);

	static [TableEnum.homepageChecks] = new TableConfig(
		dashboardTableColumns[TableEnum.homepageChecks],
		null,
		true
	);

	static [TableEnum.documents] = new TableConfig(
		dashboardTableColumns[TableEnum.documents],
		"AdminDocumentsDialog"
	);

	static [TableEnum.faqs] = new TableConfig(
		dashboardTableColumns[TableEnum.faqs],
		"AdminFaqsDialog"
	);

	static [TableEnum.invoices] = new TableConfig(
		dashboardTableColumns[TableEnum.invoices],
		null,
		true
	);

	static [TableEnum.jobOpenings] = new TableConfig(
		dashboardTableColumns[TableEnum.jobOpenings],
		"AdminJobOpeningsDialog"
	);

	static [TableEnum.localizations] = new TableConfig(
		dashboardTableColumns[TableEnum.localizations],
		"AdminLocalizationsDialog"
	);

	static [TableEnum.news] = new TableConfig(
		dashboardTableColumns[TableEnum.news],
		"AdminNewsDialog"
	);

	static [TableEnum.pages] = new TableConfig(
		dashboardTableColumns[TableEnum.pages],
		"AdminPagesDialog"
	);

	static [TableEnum.sections] = new TableConfig(
		dashboardTableColumns[TableEnum.sections],
		"AdminSectionsDialog"
	);

	static [TableEnum.testimonials] = new TableConfig(
		dashboardTableColumns[TableEnum.testimonials],
		"AdminTestimonialsDialog"
	);

	static [TableEnum.userRoleSubscriptions] = new TableConfig(
		dashboardTableColumns[TableEnum.userRoleSubscriptions]
	);

	static [TableEnum.users] = new TableConfig(
		dashboardTableColumns[TableEnum.users],
		"AdminUsersDialog"
	);
}
